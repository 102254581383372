<template>
  <AppAsideRight v-if="!$vuetify.breakpoint.xsOnly">
    <template #prepend>
      <VContainer>
        <VRow>
          <VCol>
            <h1
              class="tt-text-title-2"
            >
              {{ $t('menu.events') }}
            </h1>
          </VCol>
        </VRow>
      </VContainer>
    </template>
    <template #default>
      <VContainer>
        <VRow>
          <VCol
            cols="12"
          >
            <div
              v-for="(event,i) in events"
              :key="i"
            >
              <EventCard
                :key="i"
                shadow="hover"
                class="pa-5"
                :to="{name : Names.R_APP_EVENTS, params : {eventId : event.task.id}}"
                :task="event.task"
                height="auto"
                :expires="event.expires"
                :title-length="40"
                :card-type="event.task.type"
              />
              <VDivider
                v-if="i+1!==events.length"
                class="my-3"
              />
            </div>
          </VCol>
        </VRow>
      </VContainer>
    </template>
  </AppAsideRight>
</template>

<script>
import AppAsideRight from '@/components/app/AppAsideRight.vue';
import EventCard from '@/components/cards/EventCard.vue';
import { mapActions, mapState } from 'vuex';
import { GET_EVENTS_ACTION } from '@/plugins/vuex/actionTypes';

export default {
  name: 'EventAsideRight',
  components: { EventCard, AppAsideRight },
  inject: ['Names'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('handbook', ['events']),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('handbook', { getEventsAction: GET_EVENTS_ACTION }),
    async init() {
      try {
        this.loading = true;
        await this.getEventsAction({ data: { include_inactive: true } });
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
